<template>
  <Popup ref="popupRef" width="500px" :title="titleTxt + '账户'" @confirm="confirm" @close="resetForm">
    <el-input
      type="textarea"
      :rows="8"
      :maxlength="150"
      show-word-limit
      :placeholder="`请输入${titleTxt}理由`"
      v-model="inputValue">
    </el-input>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { userStatusOperateApi } from '@/api/admin.js'
export default {
  components: {
    Popup
  },
  data() {
    return {
      inputValue: '',
      uid: '',
      act: ''
    }
  },
  computed: {
    titleTxt() {
      if(this.act == 1) return '冻结'
      if(this.act == 3) return '注销'
      return ''
    }
  },
  methods: {
    open(option) {
      this.uid = option.uid
      this.act = option.act
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetForm() {
      this.inputValue = ''
    },
    confirm() {
      if(!this.inputValue.trim()) return this.$tips({message: `请输入${this.titleTxt}理由`, type:'warning'})
      userStatusOperateApi({
        uid: this.uid,
        act: this.act,
        type: 1,
        reason: this.inputValue.trim()
      }).then(res => {
        this.$tips({message: res.msg, type:'success'})
        this.$emit('update')
        this.close()
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>